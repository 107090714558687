<script>
  import EventBus from "@/eventbus"
  import api from "@/api"
  export default {
    name: "questionnaire-revoke-access",
    props : ["screenWidth"],
    data: function() {
      return {
      }
    },
    created() {
      this.$store.dispatch("pushPage", this.$route.name)
      this.$store.dispatch("resetQuestionnaireState")
    },
    methods: {
      confirmRevocation() {
        var params = {
          "key": this.$route.query.key, 
          "data": this.$route.query.data
        }
        api.revokeQuestionnaireAccess(params).then((response) => {
          if (response.status == 204) {
            this.$router.replace({name: "home"})
            EventBus.$emit("successMessage", "Consenso revocato")
          } else {
            this.$router.replace({name: "home"})
            EventBus.$emit("errorMessage", "Impossibile revocare il consenso all'accesso")
          }
        }).catch(() => {
          this.$router.replace({name: "home"})
          EventBus.$emit("errorMessage", "Impossibile revocare il consenso all'accesso") 
        })
      },
      abortRevocation() {
        this.$router.replace({name: "home"})
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-4-desktop is-6-tablet">
          <div class="block has-text-centered">
            <h1 class="title is-3">
              {{ $t("pageTitle") }}
            </h1>
          </div>
          <div class="block">
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message1") }}
              </div>
            </div>
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message2") }}
              </div>
            </div>
          </div>
          <div class="columns is-multiline is-mobile">
            <div class="column is-12-mobile">
              <button class="button is-medium is-outlined"
                v-bind:class="{'is-fullwidth': screenWidth <= 768}"
                v-on:click="$router.replace({name: 'home'})">
                {{$t("abortButton")}}
              </button>
            </div>
            <div class="column is-narrow is-12-mobile">
              <button class="button is-medium is-danger"
                v-bind:class="{'is-fullwidth': screenWidth <= 768}"
                v-on:click="confirmRevocation">
                {{$t("confirmButton")}}
              </button>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div> 
</template>

<i18n>
{
  "it": {
    "pageTitle": "Revoca consenso all'accesso",
    "loadedKeyTitle": "Codice temporaneo",
    "message1": "Confermando la revoca del consenso “Accesso alla Struttura” il codice QR che hai ricevuto via e-mail diventerà inutilizzabile e non potrà essere scansionato dal personale delle strutture al momento dell’accesso.",
    "message2": "Per confermare la revoca del consenso, clicca su “Conferma”. L’operazione non può essere annullata. Potrai comunque compilare un nuovo questionario ogni volta che vorrai accedere ad una struttura.",
    "confirmButton": "Conferma",
    "abortButton": "Annulla"  
  }
}
</i18n>

<style>
</style>